* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Signika', sans-serif;
  background-color: #f0f0f0;
}

.App {
  max-width: 100%;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  color: #444;
  font-size: 20px;
  margin-bottom: 15px;
}

.new-customer-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.new-customer-buttons button {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.new-customer-buttons button:hover {
  background-color: #45a049;
}

.new-customer-buttons svg {
  width: 30px;
  height: 30px;
}

.customer-list, .food-orders-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.customer-item, .food-order-item {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.customer-item span, .food-order-item span {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.customer-item div, .food-order-item div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

button {
  margin: 5px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

@media (max-width: 600px) {
  .App {
    padding: 10px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  .new-customer-buttons button {
    width: 50px;
    height: 50px;
  }

  .new-customer-buttons svg {
    width: 25px;
    height: 25px;
  }

  .customer-item, .food-order-item {
    padding: 10px;
  }

  button {
    font-size: 12px;
    padding: 6px 10px;
  }
}